import ConcordiumLogo from '../../../../assets/b2b/shared/logos/Concordium-logo.png'
import ProxidLogo from '../../../../assets/b2b/shared/logos/Proxid-logo.svg'
import React from 'react'
import RJELogo from '../../../../assets/b2b/shared/logos/RJE-logo.svg'
import { ArrowForwardIcon } from '@chakra-ui/icons'
import { Box, Center, Flex, Heading, Image, Link, useBreakpointValue, VStack } from '@chakra-ui/react'
import { NavLink } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'

export const Organisations = () => {
  const boxWidth = useBreakpointValue({ base: "95%", sm: "90%", md: "723px", lg: "933px", xl: "1127px" });
  const { t } = useTranslation();

  return (
    <Center as='section' id='organisations'>
      <Box width={boxWidth}>
        <Heading as="h2" fontSize="4xl" mb={12} color="white" textAlign={{ base: "center", md: "left" }}>
          <Trans i18nKey={"B2B.Home.Organisations.Title"}>
            placeholder <br></br>
          </Trans>
        </Heading>
        <Flex flexFlow="row wrap" justifyContent="space-between">
          <VStack spacing={3} alignItems="flex-start" backgroundColor="#02023D" flexBasis={{ base: "100%", md: "31%" }} p={8} mx={{ base: "auto", md: 0 }} mb={{ base: 4, md: 0 }}>
            <Image htmlHeight={73} htmlWidth={255} src={ConcordiumLogo} alt='logo Concordium' height="3.5rem" width="auto"></Image>
            <Box color="ciphers.main.textSecondary">{t("B2B.Home.Organisations.Concordium")}</Box>
            <Link as={NavLink} to="/about-us" color="white" pt={5} mt="auto !important" zIndex={1}>
              <ArrowForwardIcon color="white" />  {t("B2B.Home.Organisations.moreInformation")}
            </Link>
          </VStack>
          <VStack spacing={3} alignItems="flex-start" backgroundColor="#02023D" flexBasis={{ base: "100%", md: "31%" }} p={8} mx="auto" mb={{ base: 4, md: 0 }}>
            <Image htmlHeight={40} htmlWidth={122} src={ProxidLogo} alt='logo Proxid' height="3.5rem" width="auto"></Image>
            <Box color="ciphers.main.textSecondary">{t("B2B.Home.Organisations.PROXID")}</Box>
            <Link as={NavLink} to="/about-us" color="white" pt={5} mt="auto !important" zIndex={1}>
              <ArrowForwardIcon color="white" />  {t("B2B.Home.Organisations.moreInformation")}
            </Link>
          </VStack>
          <VStack spacing={3} alignItems="flex-start" backgroundColor="#02023D" flexBasis={{ base: "100%", md: "31%" }} p={8} mx="auto" mb={{ base: 4, md: 0 }}>
            <Image htmlHeight={90} htmlWidth={300} src={RJELogo} alt='logo Concordium' height="3.5rem" width="auto"></Image>
            <Box color="ciphers.main.textSecondary">{t("B2B.Home.Organisations.RJE")}</Box>
            <Link as={NavLink} to="/about-us" color="white" pt={5} mt="auto !important" zIndex={1}>
              <ArrowForwardIcon color="white" />  {t("B2B.Home.Organisations.moreInformation")}
            </Link>
          </VStack>
        </Flex>
      </Box>
    </Center >
  )
}
