import { Box, Center, Heading, ListItem, Text, UnorderedList, useBreakpointValue } from '@chakra-ui/react'

export const PrivacyPolicy = () => {
  const boxWidth = useBreakpointValue({ base: "95%", sm: "90%", md: "723px", lg: "933px", xl: "1127px" });

  return (
    <Center as='section' id='actions'>
      <Box width={boxWidth} color="whiteAlpha.600" zIndex={1}>
        <Heading as="h1" color="white" my={8}>
          PRIVACY AND COOKIE STATEMENT CIPHERS.ME
        </Heading>

        <Text as="p" color="whiteAlpha.600" mb={5}>
          This Privacy and Cookie Statement was last amended on 3 January 2023.
        </Text>

        <Text as="p" color="whiteAlpha.600" mb={2}>
          The services and websites of Ciphers.me ("Ciphers.me") process privacy-sensitive data, known as personal data. The careful handling of personal data is of great importance to us. We therefore process and protect personal data with the greatest possible care.
        </Text>

        <Text as="p" color="whiteAlpha.600" mb={5}>
          When processing data, we adhere to the requirements of privacy legislation. This means, inter alia, that we:
          <UnorderedList>
            <ListItem>clearly indicate via the Privacy and Cookie Statement the purposes for which we process personal data;</ListItem>
            <ListItem>try to limit the collection of personal data to data necessary for legitimate purposes; - ask you for your explicit consent before processing your personal data in those cases where your consent is required;</ListItem>
            <ListItem>take appropriate security measures to protect your personal data and require parties processing data on our behalf to do the same;</ListItem>
            <ListItem>respect your right to access, correct or delete your personal data upon request.</ListItem>
          </UnorderedList>
        </Text>

        <Text as="p" color="whiteAlpha.600" mb={5}>
          We are the data controller for the data processing described below. In this Privacy and Cookie Statement, we explain which personal data we collect and use in our capacity as data controller, and for what purpose. We recommend that You read this statement carefully.
        </Text>

        <Text as="p" color="whiteAlpha.600" mb={5}>
          Ciphers.me's different roles as "processor" and "data controller" As briefly mentioned above, this Privacy and Cookie Statement applies in situations where Ciphers.me can be considered a "data controller", i.e. situations where Ciphers.me determines the purpose and means of data processing.
        </Text>

        <Text as="p" color="whiteAlpha.600" mb={5}>
          There are many situations in which Ciphers.me, as a cloud service provider, assumes the role of 'processor'. In that role, Ciphers.me processes personal data on behalf of its customers. In this case, it is the customers of the services in question who decide that their personal data will be processed and instruct Ciphers.me as the provider to do so. Ciphers.me then carries out the assignment; it does not make any independent decisions regarding these personal data. Ciphers.me is explicitly not a controller of the processing of these personal data. In such cases, you will have to be informed of the data processing by the organisation from which you purchased the service.
        </Text>

        <Text as="p" color="whiteAlpha.600" mb={5}>
          It is also possible that, as a provider of our cloud services, we may nevertheless have to assume the role of 'data controller'. In that case, you will be informed in advance, before you start using our cloud services, that we as an independent organisation process the data for you as a customer.
        </Text>

        <Text as="p" color="whiteAlpha.600" mb={5}>
          Use of personal data<br />
          When using our website or service, you submit certain data to us, which may include personal data. We collect and process this data only for the purpose for which you provide it to us. Personal data may only be processed for specific, explicitly defined and legitimate purposes. These purposes are described in more detail below.
        </Text>

        <Text as="p" color="whiteAlpha.600" mb={5}>
          In addition, the collection and processing of your personal data is always based on one of the following legal grounds:
          <UnorderedList>
            <ListItem>You have consented to the processing of your personal data.</ListItem>
            <ListItem>The processing is necessary for the performance of an agreement and our services or our client's services.</ListItem>
            <ListItem>We have a legitimate interest in processing your data.</ListItem>
            <ListItem>We are legally obliged to process your data.</ListItem>
          </UnorderedList>
          Below, we describe the legal basis for processing your data for each purpose and provide more information on our processing operations.
        </Text>

        <Text as="p" color="whiteAlpha.600" mb={5}>
          Purposes<br />
          The purposes for which we process your personal data are explained in more detail below.
        </Text>

        <Text as="p" color="whiteAlpha.600" mb={5}>
          Requesting a quote via the website<br />
          Through our website or other communication channels, you can request a quotation without any obligation. To do so, we ask you for your company name, first and last name, e-mail address and telephone number. We use this data to prepare and send the quotation, and to communicate about the quotation and services.
        </Text>

        <Text as="p" color="whiteAlpha.600" mb={5}>
          We process the above data as part of the preparations for the conclusion of the agreement between you and Ciphers.me.
        </Text>

        <Text as="p" color="whiteAlpha.600" mb={5}>
          Provision of data to third parties<br />
          We record your data in our CRM application and online accounting package. In addition, we store the signed quotations within the cloud environment of our IT supplier.
        </Text>

        <Text as="p" color="whiteAlpha.600" mb={5}>
          Product-specific requirements for the purchase of services<br />
          In certain cases, Ciphers.me is required by law, or pursuant to obligations imposed by its suppliers, to obtain additional information from you.
        </Text>

        <Text as="p" color="whiteAlpha.600" mb={5}>
          We process the above data to comply with our legal obligations.
        </Text>

        <Text as="p" color="whiteAlpha.600" mb={5}>
          Provision of data to third parties<br />
          The above data is stored within the cloud environment of our IT service provider and is only provided to them upon request.
        </Text>

        <Text as="p" color="whiteAlpha.600" mb={5}>
          Requesting an online demo, API information<br />
          You can request an appointment for an online demo via the website. To schedule the appointment for the online demo, you must provide us with your name, e-mail address and telephone number. To request API information, you need to provide your company name, name, e-mail address and phone number.
        </Text>

        <Text as="p" color="whiteAlpha.600" mb={5}>
          We process the above data on the basis of the agreement for providing the request for quotation or API information.
        </Text>

        <Text as="p" color="whiteAlpha.600" mb={5}>
          Provision of data to third parties<br />
          We include your data in our CRM application. Your data is also processed within the cloud environment of our IT service provider.
        </Text>

        <Text as="p" color="whiteAlpha.600" mb={5}>
          Processing your orders and providing services<br />
          When you place an order with us you agree to our offer, we use your personal data to handle the transaction properly. We use your personal data to provide the services, such as informing you about the status of the services, as well as arranging the registration procedure, providing backups, creating your account and performing management and support tasks. For these purposes, we use your name and address details, telephone number, e-mail address and company name.
        </Text>

        <Text as="p" color="whiteAlpha.600" mb={5}>
          Provision of data to third parties<br />
          In this processing, your data will be made available to the supplier of our CRM application.
        </Text>

        <Text as="p" color="whiteAlpha.600" mb={5}>
          Providing support<br />
          Ciphers.me provides support in various ways, for example by e-mail, telephone and via our chat module. Please refer to the Service Level Agreement (SLA) you entered into with us for the options for registering your questions and/or incidents. For example, you can start a chat via our website or within the online environment, send an e-mail to our support desk or ask your questions by phone. If you send an e-mail, a ticket is created internally and your question is forwarded to the relevant department.
        </Text>

        <Text as="p" color="whiteAlpha.600" mb={5}>
          Through our ticketing system, customers who have purchased an SLA have their own environment in which we can view data such as the status of tickets linked to their organisation.
        </Text>

        <Text as="p" color="whiteAlpha.600" mb={5}>
          Depending on the method chosen, we ask you to share certain information with us. This may include your name and address details, and possibly your e-mail address, telephone number, customer number and any data you provide yourself. In certain cases, we process internal notes in connection with your request for support. You may also share a document containing signatories' details upon request.
        </Text>

        <Text as="p" color="whiteAlpha.600" mb={5}>
          We process this data under the terms of our agreement with you.
        </Text>

        <Text as="p" color="whiteAlpha.600" mb={5}>
          Provision of data to third parties.<br />
          For communication via e-mail, contact form or chat, we use the services of IT service providers, such as a ticketing system, a chat module and a support tool. Finally, your data may be entered into our CRM system.
        </Text>

        <Text as="p" color="whiteAlpha.600" mb={5}>
          Requesting a whiteAlpha.600 paper<br />
          You can request whiteAlpha.600 papers via our website. The whiteAlpha.600 paper will be sent to you after you enter your company name and e-mail. We use this data to send you the whiteAlpha.600 paper and to follow it up, for example to ask you if the information is clear, and to invite you to a meeting or to subscribe to our newsletter.
        </Text>

        <Text as="p" color="whiteAlpha.600" mb={5}>
          We process the above data on the basis of your consent.
        </Text>

        <Text as="p" color="whiteAlpha.600" mb={5}>
          Provision of data to third parties<br />
          Your data will be provided to the e-mail service provider we use when sending e-mails. In addition, your data will be processed in our CRM application.
        </Text>

        <Text as="p" color="whiteAlpha.600" mb={5}>
          Communication via the contact form or via chat, telephone, e-mail or video conference
        </Text>

        <Text as="p" color="whiteAlpha.600" mb={5}>
          Contact form<br />
          You have the opportunity to ask questions, make a complaint or report a possible security incident, via a contact form on the website. In order for us to process your request, complaint or report, you must provide your name, company name, e-mail address and telephone number.
        </Text>

        <Text as="p" color="whiteAlpha.600" mb={5}>
          Contact by phone<br />
          If you have a question, you can also contact Ciphers.me by phone. In certain cases, we ask for your name, phone number and company name, for example to call you back later.
        </Text>

        <Text as="p" color="whiteAlpha.600" mb={5}>
          Using the chat function via the website<br />
          Our website also has a chat function. In principle, you do not need to share any data to use the chat. However, during the chat session we may ask you to share some data, such as your company name, e-mail address and telephone number, so that we can contact you again later.
        </Text>

        <Text as="p" color="whiteAlpha.600" mb={5}>
          Ask questions by e-mail<br />
          You can also ask your questions by e-mail; in that case, we will process at least your (business) e-mail address and name, and any other data you share with us.
        </Text>

        <Text as="p" color="whiteAlpha.600" mb={5}>
          Conference call<br />
          Finally, we may arrange a video conference call with you. In that case, we will process at least your e-mail address to send you the invitation. You will then have the choice to join the conference call, provide a username and participate with or without video functionality.
        </Text>

        <Text as="p" color="whiteAlpha.600" mb={5}>
          We use this data to answer your questions, communicate with you and send you the necessary information. If you are a customer, we process this data under the terms of our agreement with you.
        </Text>

        <Text as="p" color="whiteAlpha.600" mb={5}>
          If you are not a customer, we process this data based on our legitimate interest to answer your questions.
        </Text>

        <Text as="p" color="whiteAlpha.600" mb={5}>
          Provision of data to third parties<br />
          For communication via e-mail, contact form or chat, we use the services of IT service providers, such as a ticketing system, a chat module and a video conferencing tool. The providers of these services may have knowledge of your data. In addition, we process some of your data in our CRM application.
        </Text>

        <Text as="p" color="whiteAlpha.600" mb={5}>
          Financial administration<br />
          If you purchase services from Ciphers.me, you will be charged a fee. To collect this fee, we need certain data from you, for example to execute the direct debit authorisation or to send you an invoice.
        </Text>

        <Text as="p" color="whiteAlpha.600" mb={5}>
          If you are our supplier and we purchase services from you, we process certain data to process and pay your invoice.
        </Text>

        <Text as="p" color="whiteAlpha.600" mb={5}>
          For these purposes, we process your name and address details, payment details, e-mail address, telephone number, Chamber of Commerce number, tax details, invoices, name of contact person, position and company name.
        </Text>

        <Text as="p" color="whiteAlpha.600" mb={5}>
          We process this data under the terms of our agreement with you.
        </Text>

        <Text as="p" color="whiteAlpha.600" mb={5}>
          Provision of data to third parties<br />
          Your data is recorded in our ticketing tool, CRM application and invoicing tool and by the accounting firm that handles our administration.
        </Text>

        <Text as="p" color="whiteAlpha.600" mb={5}>
          Supplier data<br />
          When Ciphers.me engages suppliers, it will record specific contact details in its systems for payment and billing purposes, for communication purposes and for the purpose of providing services from the supplier in question. In such cases, Ciphers.me processes the names, address data, e-mail address, telephone number, company name and payment and billing data.
        </Text>

        <Text as="p" color="whiteAlpha.600" mb={5}>
          We process the above data under the terms of our agreement with you.
        </Text>

        <Text as="p" color="whiteAlpha.600" mb={5}>
          Applying for jobs at Ciphers.me<br />
          Through our website, you can respond to vacancies or send an open application. You can also submit your application through other communication channels of Ciphers.me, such as the contact page or by e-mail. We may ask you to provide necessary information, such as your name and address details, telephone number, CV, certificate of good conduct, marksheets, diplomas, cover letter and any references.
        </Text>

        <Text as="p" color="whiteAlpha.600" mb={5}>
          We will keep your data during the application process and no longer than 12 weeks after its completion. However, with your consent, we may retain this data for up to one year in order to contact you should a relevant vacancy arise in the future.
        </Text>

        <Text as="p" color="whiteAlpha.600" mb={5}>
          We process the above data on the basis of our legitimate interest to assess, for example, whether we will employ you on the basis of your application and, in certain cases, on the basis of your consent as required to ultimately conclude a contract.
        </Text>

        <Text as="p" color="whiteAlpha.600" mb={5}>
          Provision of data to third parties<br />
          In certain cases, we may require the assistance of an external company for recruitment services. In such a case, we may provide your data to them as part of your application. In addition, certain data are processed within the cloud environment of our IT service provider, for example to schedule an online appointment.
        </Text>

        <Text as="p" color="whiteAlpha.600" mb={5}>
          Improving our services and informing you about service-related developments<br />
          As part of product management, Ciphers.me records possible new functionality proposed by its customers and users. In doing so, Ciphers.me processes only the company name and, in certain cases, the name of the contact person.
        </Text>

        <Text as="p" color="whiteAlpha.600" mb={5}>
          In addition, Ciphers.me informs you via the account environment about new functionality, maintenance work, any malfunctions and other matters relevant to the services you purchase from us. In doing so, we only process your account data to ensure that the message reaches you.
        </Text>

        <Text as="p" color="whiteAlpha.600" mb={5}>
          We process this data under the terms of our agreement with you.
        </Text>
        <Text as="p" color="whiteAlpha.600" mb={5}>
          Provision of data to third parties<br />
          We use the e-mail services of a third party to send newsletters. We use a third-party registration tool to register new product features.
        </Text>

        <Text as="p" color="whiteAlpha.600" mb={5}>
          Statistics<br />
          We keep statistics on the use of our services and the website. The statistics cannot be traced back to an individual.
        </Text>


        <Text as="p" color="whiteAlpha.600" mb={5}>
          We process this data based on the consent you have granted via the cookie banner and on our legitimate interest in relation to these general statistics.
        </Text>

        <Text as="p" color="whiteAlpha.600" mb={5}>
          Retention periods<br />
          We do not retain data for the above purposes for longer than necessary. This means that we keep your data for as long as necessary, e.g. to provide our services to you, to perform the contract, or until we are sure that you are satisfied with our services, until we have answered all your questions.We may need to keep your data longer in connection with a legal retention obligation, e.g. tax retention obligations.
        </Text>

        <Text as="p" color="whiteAlpha.600" mb={5}>
          Provision of data to third parties<br />
          Apart from the provision of your personal data to third parties as described above, we do not share your data with other parties without your consent, unless we are required to do so by law.
        </Text>

        <Text as="p" color="whiteAlpha.600" mb={5}>
          We enter into strict agreements with the parties receiving your data that clearly state which personal data will be shared and why. We will ensure that your data is not used for other purposes. We will also ensure that these third parties protect and destroy your data as soon as they no longer need it.
        </Text>

        <Text as="p" color="whiteAlpha.600" mb={5}>
          Cookies<br />
          Our website makes use of cookies. A cookie is a simple small file sent with pages from this website and stored by your browser on your computer's hard drive so that you can be recognised. In this Privacy and Cookie Statement, the term 'cookie' also refers to other similar (tracking) techniques (e.g. pixels). Third parties engaged by us also place cookies on your device.
        </Text>

        <Text as="p" color="whiteAlpha.600" mb={5}>
          The first time you visit our website, a pop-up will appear indicating that you accept cookies by clicking the 'agree' button on our website. By clicking this button, you allow us to use all cookies and plug-ins as set out in the pop-up and in this Privacy and Cookie Statement. You are free to disable cookies in your browser. Please note that this may mean that our website no longer functions optimally.
        </Text>

        <Text as="p" color="whiteAlpha.600" mb={5}>
          We have no control over what parties themselves do with cookies. Cookies have an expiry date: some are session cookies and others have a different, specific retention period. If you want, you can delete cookies earlier. This is a manual operation; consult your browser's manual.
        </Text>

        <Text as="p" color="whiteAlpha.600" mb={5}>
          Security<br />
          We take security measures to minimise the risk of misuse of and unauthorised access to personal data. As described in our ISMS.
        </Text>

        <Text as="p" color="whiteAlpha.600" mb={5}>
          Third-party websites<br />
          This statement does not apply to third-party websites linked to our website. We cannot guarantee that these parties will handle your personal data reliably or securely. We therefore recommend that you read the privacy statements of these websites before using them.
        </Text>

        <Text as="p" color="whiteAlpha.600" mb={5}>
          Transfer outside the EEA<br />
          Our suppliers may store information in and transfer it to the United States. We are aware of the European Court of Justice ruling of 16 July 2020, which affects the transfer of personal data. Our servers and storage are only located in Europe. We do not need to transfer any information to the United States. If you have any questions about the processing of your personal data, please contact us using the contact details in this privacy notice.
        </Text>

        <Text as="p" color="whiteAlpha.600" mb={5}>
          Changes to this privacy and cookie statement<br />
          We reserve the right to amend this statement. New versions will be published on this website and we will ensure that old versions remain available. We recommend that you consult this statement regularly so that you are aware of these changes.
        </Text>

        <Text as="p" color="whiteAlpha.600" mb={5}>
          Your rights<br />
          You can contact us at any time if you have any questions or would like to know what personal data we hold about you. Send your request to privacy@ciphers.me
        </Text>

        <Text as="p" color="whiteAlpha.600" mb={5}>
          You have the following rights:
          <UnorderedList>
            <ListItem>to obtain an explanation of what personal data we have collected about you and how we use that data;</ListItem>
            <ListItem>to access the specific personal data we have collected;</ListItem>
            <ListItem>to have errors corrected and your personal data completed if they are inaccurate or incomplete; to have personal data deleted;</ListItem>
            <ListItem>to withdraw your consent; to restrict specific processing operations; to</ListItem>
            <ListItem>to object to a particular use (e.g. processing based on a legitimate interest or for direct marketing purposes)</ListItem>
          </UnorderedList>
        </Text>

        <Text as="p" color="whiteAlpha.600" mb={5}>
          To prevent abuse and fraud, we may ask you to identify yourself properly. In principle, we will comply with your request within one month. However, this period may be extended for reasons related to the specific rights of data subjects or the complexity of the request. If we extend this period, we will notify you in good time.
        </Text>

        <Text as="p" color="whiteAlpha.600" mb={5}>
          Personal data authority<br />
          Naturally, we are happy to assist you if you have complaints about the processing of your personal data. Under privacy legislation, you also have the right to lodge a complaint with the Dutch Data Protection Authority against the processing of your personal data.
        </Text>

        <Text as="p" color="whiteAlpha.600" mb={5}>
          Contact details<br />
          If you have any questions about your privacy and our Privacy and Cookie Statement, please send an email using the following contact details: <br></br>
          Ciphers.me<br />
          Palletweg 78<br />
          2031 DC Haarlem<br />
          The Netherlands<br />
          +31 (0)23 518 4515<br />
          <a href="mailto:privacy@ciphers.me">privacy@ciphers.me</a>
        </Text>
      </Box>
    </Center>
  )
}
