import arrowRight from '../../../../assets/b2b/home/ArrowRight.svg'
import background from '../../../../assets/b2b/home/hand-with-phone.svg'
import QrReader from 'react-qr-reader'
import React, { useRef, useState } from 'react'
import stamp from '../../../../assets/b2b/home/Stamp.svg'
import { AxiosError } from 'axios'
import { Box, Button, Center, Flex, Heading, Img, PinInput, PinInputField, Text, useBreakpointValue, useToast } from '@chakra-ui/react'
import { CloseIcon } from '@chakra-ui/icons'
import { NavLink, useNavigate } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'
import { useValidateMarketingCodeMutattion } from '../../../../api/queries/b2b/useMarketingCode'
import { ValidateMarketingCodeFormValues } from '../../../../models/b2b/MarketingCode'

export const Stamps = () => {
  const [showManualInput, setShowManualInput] = useState(false)
  const [secondBlockIsHovered, setSecondBlockIsHovered] = useState<boolean>(false)
  const [showQr, setShowQr] = useState<boolean>(false)
  const boxWidth = useBreakpointValue({ base: "95%", sm: "90%", md: "723px", lg: "933px", xl: "1127px" });
  const isMobile = useBreakpointValue({ base: true, lg: false })
  const displayQrCode = useBreakpointValue({ base: true, md: false })
  const codeSubmit = useValidateMarketingCodeMutattion()
  const wrapper1Ref = useRef<HTMLDivElement>(null)
  const wrapper2Ref = useRef<HTMLDivElement>(null)
  const wrapper3Ref = useRef<HTMLDivElement>(null)
  const wrapper4Ref = useRef<HTMLDivElement>(null)
  const wrapper5Ref = useRef<HTMLDivElement>(null)
  const wrapper6Ref = useRef<HTMLDivElement>(null)
  const toast = useToast();
  const navigate = useNavigate();
  const { t } = useTranslation()
  const mobileHeight = showManualInput ? "40rem" : "30rem"

  const handleCodeComplete = (code: string) => {
    const valueToSubmit: ValidateMarketingCodeFormValues = {
      code: code,
    }
    codeSubmit.mutate(valueToSubmit, {
      onSuccess() {
        navigate(`/postal-action/${code}`)
      },
      onError: (error: AxiosError) => {
        if (error.response?.status === 404) {
          toast({
            title: t("B2B.Home.Stamps.Error.Title"),
            description: t("B2B.Home.Stamps.Error.Description"),
            status: "error",
            colorScheme: "red",
            duration: 3 * 1000,
            isClosable: true,
            position: "top"
          })
        }
      }
    })
  }

  const handleBoxClick = (ref: React.RefObject<HTMLDivElement>, position: number = 0): void => {
    if (position === 4) return;
    if (!(ref.current?.childNodes[position] as HTMLInputElement)?.value) {
      return (ref.current?.childNodes[position] as HTMLInputElement)?.focus()
    } else {
      return handleBoxClick(ref, position + 1)
    }
  }

  const handleQrScan = (scannedResult: any) => {
    if (scannedResult) {
      window.location.assign(scannedResult)
      setShowQr(false)
    }
  }

  return (
    <Center as='section' id='stamps'>
      <Box width={boxWidth} display="flex" flexFlow="row wrap" justifyContent="space-between">
        {showQr ? (
          <Box
            position="relative"
            backgroundColor={secondBlockIsHovered ? "#101152" : "#4129FF"}
            flex={{ base: "0 0 100%", md: "0 0 48%" }}
            mb={{ base: 4, lg: 0 }}
            height={{ base: mobileHeight, md: "30rem" }}
            zIndex={1}
          >
            <QrReader
              facingMode="environment"
              onScan={handleQrScan}
              onError={(error) => { }}
            />
          </Box>
        ) : (
          <Box
            position="relative"
            backgroundColor={secondBlockIsHovered ? "#101152" : "#4129FF"}
            flex={{ base: "0 0 100%", md: "0 0 48%" }}
            mb={{ base: 4, lg: 0 }}
            height={{ base: mobileHeight, md: showManualInput ? "auto" : "30rem" }}
            zIndex={1}
            _before={{
              content: "''",
              position: "absolute",
              top: "0px",
              right: "0px",
              bottom: "0px",
              left: "0px",
              opacity: `${showManualInput ? 0.1 : 1}`,
              bgImage: `url(${background})`,
              bgPosition: "bottom center",
              bgRepeat: "no-repeat",
              bgSize: "220%",
              zIndex: "-1"
            }}
          >
            <Box p={{ base: "2rem 2rem 0rem 2rem", md: "3rem 3rem 0rem 3rem" }} pb={0}>
              <Heading display="block" as="h3" mr="auto" mb={4} color={!secondBlockIsHovered ? "whiteAlpha.900" : "#6A77FF"}>{t("B2B.Home.Stamps.Received.Title")}</Heading>
              <Text
                display="block"
                color={!secondBlockIsHovered ? "whiteAlpha.900" : "#6A77FF"}
              >
                {t("B2B.Home.Stamps.Received.Description")}
              </Text>
              <Flex justifyContent="space-between">
                {displayQrCode && (
                  <Text
                    onClick={() => setShowQr(true)}
                    display="block"
                    color="whiteAlpha.500"
                    textDecoration="underline"
                    _hover={{
                      cursor: "pointer"
                    }}
                  >
                    {t("Open your camera")}
                  </Text>
                )}
                <Text
                  onClick={() => setShowManualInput(true)}
                  display="block"
                  color="whiteAlpha.500"
                  textDecoration="underline"
                  _hover={{
                    cursor: "pointer"
                  }}
                >
                  {t("B2B.Home.Stamps.Received.Manual")}
                </Text>
              </Flex>
            </Box>
            {showManualInput && (
              <Box p={"1rem 1rem 1rem 1rem"}>
                <Box backgroundColor="#1A099F" p={5} pr={3} borderRadius={4} position="relative">
                  <CloseIcon
                    position="absolute"
                    top={3}
                    right={3}
                    color="whiteAlpha.600"
                    w={3}
                    h={3}
                    onClick={() => setShowManualInput(false)}
                    _hover={{
                      cursor: "pointer"
                    }}
                  />
                  <Text as="p" mb={4} color="white">
                    <Trans i18nKey="B2B.Home.Stamps.Received.ManualDescription">
                      Placeholder <br></br>
                    </Trans>
                  </Text>
                  <Flex alignItems="center" justifyContent="space-between">
                    <Img src={stamp} mr={2} transform="scale(0.9)" flexBasis="12%" />
                    <Box as={PinInput} display="flex" type='alphanumeric' placeholder='-' variant="filled" onComplete={handleCodeComplete}>
                      <Box display="flex" flexFlow="row wrap" justifyContent={{ base: "space-between", lg: "space-evenly" }} background="transparent" border="none" height="fit-content" flexBasis="86%" _hover={{ background: "transparent" }}>
                        <Box ref={wrapper1Ref} display="flex" flexBasis={{ base: "32%", lg: "30%" }} flexDirection="row" justifyContent="space-evenly" borderRadius={4} backgroundColor="white" border="none" mb={2} onClick={() => handleBoxClick(wrapper1Ref)}>
                          <PinInputField display="block" autoCapitalize='none' textAlign="center" color="black" backgroundColor="white" border="none" maxWidth="15px" height="40px" borderRadius={0} _focusVisible={{ border: "none", boxShadow: "none", outline: "none" }} _placeholder={{ color: "black" }} />
                          <PinInputField display="block" autoCapitalize="none" textAlign="center" color="black" backgroundColor="white" border="none" maxWidth="15px" height="40px" borderRadius={0} _focusVisible={{ border: "none", boxShadow: "none", outline: "none" }} _placeholder={{ color: "black" }} />
                          <PinInputField display="block" autoCapitalize="none" textAlign="center" color="black" backgroundColor="white" border="none" maxWidth="15px" height="40px" borderRadius={0} _focusVisible={{ border: "none", boxShadow: "none", outline: "none" }} _placeholder={{ color: "black" }} />
                          <PinInputField display="block" autoCapitalize="none" textAlign="center" color="black" backgroundColor="white" border="none" maxWidth="15px" height="40px" borderRadius={0} _focusVisible={{ border: "none", boxShadow: "none", outline: "none" }} _placeholder={{ color: "black" }} />
                        </Box>
                        <Box ref={wrapper2Ref} display="flex" flexBasis={{ base: "32%", lg: "30%" }} flexDirection="row" justifyContent="space-evenly" borderRadius={4} backgroundColor="white" border="none" mb={2} onClick={() => handleBoxClick(wrapper2Ref)}>
                          <PinInputField display="block" autoCapitalize="none" textAlign="center" color="black" backgroundColor="white" border="none" maxWidth="15px" height="40px" borderRadius={0} _focusVisible={{ border: "none", boxShadow: "none", outline: "none" }} _placeholder={{ color: "black" }} />
                          <PinInputField display="block" autoCapitalize="none" textAlign="center" color="black" backgroundColor="white" border="none" maxWidth="15px" height="40px" borderRadius={0} _focusVisible={{ border: "none", boxShadow: "none", outline: "none" }} _placeholder={{ color: "black" }} />
                          <PinInputField display="block" autoCapitalize="none" textAlign="center" color="black" backgroundColor="white" border="none" maxWidth="15px" height="40px" borderRadius={0} _focusVisible={{ border: "none", boxShadow: "none", outline: "none" }} _placeholder={{ color: "black" }} />
                          <PinInputField display="block" autoCapitalize="none" textAlign="center" color="black" backgroundColor="white" border="none" maxWidth="15px" height="40px" borderRadius={0} _focusVisible={{ border: "none", boxShadow: "none", outline: "none" }} _placeholder={{ color: "black" }} />
                        </Box>
                        <Box ref={wrapper3Ref} display="flex" flexBasis={{ base: "32%", lg: "30%" }} flexDirection="row" justifyContent="space-evenly" borderRadius={4} backgroundColor="white" border="none" mb={2} onClick={() => handleBoxClick(wrapper3Ref)}>
                          <PinInputField display="block" autoCapitalize="none" textAlign="center" color="black" backgroundColor="white" border="none" maxWidth="15px" height="40px" borderRadius={0} _focusVisible={{ border: "none", boxShadow: "none", outline: "none" }} _placeholder={{ color: "black" }} />
                          <PinInputField display="block" autoCapitalize="none" textAlign="center" color="black" backgroundColor="white" border="none" maxWidth="15px" height="40px" borderRadius={0} _focusVisible={{ border: "none", boxShadow: "none", outline: "none" }} _placeholder={{ color: "black" }} />
                          <PinInputField display="block" autoCapitalize="none" textAlign="center" color="black" backgroundColor="white" border="none" maxWidth="15px" height="40px" borderRadius={0} _focusVisible={{ border: "none", boxShadow: "none", outline: "none" }} _placeholder={{ color: "black" }} />
                          <PinInputField display="block" autoCapitalize="none" textAlign="center" color="black" backgroundColor="white" border="none" maxWidth="15px" height="40px" borderRadius={0} _focusVisible={{ border: "none", boxShadow: "none", outline: "none" }} _placeholder={{ color: "black" }} />
                        </Box>
                        <Box ref={wrapper4Ref} display="flex" flexBasis={{ base: "32%", lg: "30%" }} flexDirection="row" justifyContent="space-evenly" borderRadius={4} backgroundColor="white" border="none" mb={2} onClick={() => handleBoxClick(wrapper4Ref)}>
                          <PinInputField display="block" autoCapitalize="none" textAlign="center" color="black" backgroundColor="white" border="none" maxWidth="15px" height="40px" borderRadius={0} _focusVisible={{ border: "none", boxShadow: "none", outline: "none" }} _placeholder={{ color: "black" }} />
                          <PinInputField display="block" autoCapitalize="none" textAlign="center" color="black" backgroundColor="white" border="none" maxWidth="15px" height="40px" borderRadius={0} _focusVisible={{ border: "none", boxShadow: "none", outline: "none" }} _placeholder={{ color: "black" }} />
                          <PinInputField display="block" autoCapitalize="none" textAlign="center" color="black" backgroundColor="white" border="none" maxWidth="15px" height="40px" borderRadius={0} _focusVisible={{ border: "none", boxShadow: "none", outline: "none" }} _placeholder={{ color: "black" }} />
                          <PinInputField display="block" autoCapitalize="none" textAlign="center" color="black" backgroundColor="white" border="none" maxWidth="15px" height="40px" borderRadius={0} _focusVisible={{ border: "none", boxShadow: "none", outline: "none" }} _placeholder={{ color: "black" }} />
                        </Box>
                        <Box ref={wrapper5Ref} display="flex" flexBasis={{ base: "32%", lg: "30%" }} flexDirection="row" justifyContent="space-evenly" borderRadius={4} backgroundColor="white" border="none" mb={2} onClick={() => handleBoxClick(wrapper5Ref)}>
                          <PinInputField display="block" autoCapitalize="none" textAlign="center" color="black" backgroundColor="white" border="none" maxWidth="15px" height="40px" borderRadius={0} _focusVisible={{ border: "none", boxShadow: "none", outline: "none" }} _placeholder={{ color: "black" }} />
                          <PinInputField display="block" autoCapitalize="none" textAlign="center" color="black" backgroundColor="white" border="none" maxWidth="15px" height="40px" borderRadius={0} _focusVisible={{ border: "none", boxShadow: "none", outline: "none" }} _placeholder={{ color: "black" }} />
                          <PinInputField display="block" autoCapitalize="none" textAlign="center" color="black" backgroundColor="white" border="none" maxWidth="15px" height="40px" borderRadius={0} _focusVisible={{ border: "none", boxShadow: "none", outline: "none" }} _placeholder={{ color: "black" }} />
                          <PinInputField display="block" autoCapitalize="none" textAlign="center" color="black" backgroundColor="white" border="none" maxWidth="15px" height="40px" borderRadius={0} _focusVisible={{ border: "none", boxShadow: "none", outline: "none" }} _placeholder={{ color: "black" }} />
                        </Box>
                        <Box ref={wrapper6Ref} display="flex" flexBasis={{ base: "32%", lg: "30%" }} flexDirection="row" justifyContent="space-evenly" borderRadius={4} backgroundColor="white" border="none" mb={2} onClick={() => handleBoxClick(wrapper6Ref)}>
                          <PinInputField display="block" autoCapitalize="none" textAlign="center" color="black" backgroundColor="white" border="none" maxWidth="15px" height="40px" borderRadius={0} _focusVisible={{ border: "none", boxShadow: "none", outline: "none" }} _placeholder={{ color: "black" }} />
                          <PinInputField display="block" autoCapitalize="none" textAlign="center" color="black" backgroundColor="white" border="none" maxWidth="15px" height="40px" borderRadius={0} _focusVisible={{ border: "none", boxShadow: "none", outline: "none" }} _placeholder={{ color: "black" }} />
                          <PinInputField display="block" autoCapitalize="none" textAlign="center" color="black" backgroundColor="white" border="none" maxWidth="15px" height="40px" borderRadius={0} _focusVisible={{ border: "none", boxShadow: "none", outline: "none" }} _placeholder={{ color: "black" }} />
                          <PinInputField display="block" autoCapitalize="none" textAlign="center" color="black" backgroundColor="white" border="none" maxWidth="15px" height="40px" borderRadius={0} _focusVisible={{ border: "none", boxShadow: "none", outline: "none" }} _placeholder={{ color: "black" }} />
                        </Box>
                      </Box>
                    </Box>
                  </Flex>
                  {!isMobile && (
                    <Img src={arrowRight} ml={6} />
                  )}
                </Box>
              </Box>
            )}
          </Box>
        )}

        <Box
          backgroundColor={secondBlockIsHovered ? "#4129FF" : "#101152"}
          flex={{ base: "0 0 100%", md: "0 0 48%" }}
          mb={{ base: 4, lg: 0 }}
          onMouseEnter={() => setSecondBlockIsHovered(true)}
          onMouseLeave={() => setSecondBlockIsHovered(false)}
        >
          <Box p={{ base: 8, md: 12 }}>
            <Heading as="h3" color={secondBlockIsHovered ? "whiteAlpha.900" : "#6A77FF"} mb={4}>{t("B2B.Home.Stamps.NotReceived.Title")}</Heading>
            <Text color={secondBlockIsHovered ? "whiteAlpha.900" : "#6A77FF"} mb={4}>{t("B2B.Home.Stamps.NotReceived.Description")}</Text>
            <Button as={NavLink} to="/contact" variant="b2bQuaternary" mb={4} width={{ base: "100%", md: "fit-content" }}>{t("B2B.Home.Stamps.NotReceived.Button").toUpperCase()}</Button>
            <Text color={secondBlockIsHovered ? "whiteAlpha.900" : "#6A77FF"}>{t("B2B.Home.Stamps.NotReceived.Message")}</Text>
          </Box>
        </Box>
      </Box>
    </Center>
  )
}
