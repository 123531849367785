import React from 'react'
import { AspectRatio, Box, Center, Heading, Text, useBreakpointValue, VStack } from '@chakra-ui/react'
import { Gallery } from '../../../../components/Gallery/Gallery'
import { Trans, useTranslation } from 'react-i18next'

export const Roadmap = () => {
  const boxWidth = useBreakpointValue({ base: "95%", sm: "90%", md: "723px", lg: "933px", xl: "1127px" });
  const translation = useBreakpointValue({ base: "B2B.Home.Roadmap.TitleMobile", md: "B2B.Home.Roadmap.Title" });
  const isMobile = useBreakpointValue({ base: true, md: false });
  const { t } = useTranslation()

  const mobileGallery = () => (
    <Center position="relative">
      <Box width={{ base: "300px", md: "450px" }}>
        <Gallery
          aspectRatio={4 / 5}
          height={{ base: "45rem", md: "35rem" }}
          items={[
            <Box position="relative" backgroundColor="ciphers.blueZodiac" px={6} py={10} borderRadius={16}>
              <AspectRatio
                width="100%"
                ratio={1 / 1}
                position="absolute"
                bottom="-20%"
                right="-30%"
                filter="blur(30px)"
                _after={{
                  content: '""',
                  position: "absolute",
                  top: 0,
                  right: 0,
                  bottom: 0,
                  left: 0,
                  zIndex: -1,
                  transform: "scale(1.5)",
                  clipPath: "polygon(50% 0, 90% 20%, 90% 80%, 50% 100%, 10% 80%, 10% 20%)",
                  bgGradient: 'linear(to-r, #33159D, #38ffef)'
                }}
              >
                {/* Add empty Box because AspectRatio needs at least one child */}
                <Box></Box>
              </AspectRatio>
              <VStack spacing={4} alignItems="flex-start" alignSelf="flex-start" zIndex="1">
                <Heading as="h3" color="white" height="5rem">AR, DMS, NFC, RFID, etc.</Heading>
                <Box borderRadius="8px" backgroundColor="rgba(255, 255, 255, 0.20)" p="2" >
                  <Text as="span" color="white" opacity="0.5">
                    Available
                  </Text>
                </Box>
                <Text as="p" color="white" mb={2}>
                  Ciphers.me offers several innovations that can add extra value to the familiar postage stamp. From augmented reality to RFID and NFC chips, NFTs and blockchain based secure solutions. For consumers, these innovations mean that they can unlock interactive experiences with a stamp, or track and trace their shipments with a stamp.
                </Text>
                <Text as="p" color="white" mb={2}>
                  Postal organisations can use our digital technology to add value to the traditional stamp, prevent forgery, optimise processes and offer their customers additional functionality like augmented reality and metaverses
                </Text>
              </VStack>
            </Box>,
            <Box position="relative" backgroundColor="ciphers.blueZodiac" px={6} py={10} borderRadius={16}>
              <AspectRatio
                width="100%"
                ratio={1 / 1}
                position="absolute"
                top="-20%"
                right="-40%"
                filter="blur(30px)"
                transform="rotateZ(180deg)"
                _after={{
                  content: '""',
                  position: "absolute",
                  top: 0,
                  right: 0,
                  bottom: 0,
                  left: 0,
                  zIndex: -1,
                  clipPath: "polygon(50% 0, 90% 20%, 90% 80%, 50% 100%, 10% 80%, 10% 20%)",
                  bgGradient: 'linear(to-r, #A72CD4, #33159D)'
                }}
              >
                {/* Add empty Box because AspectRatio needs at least one child */}
                <Box></Box>
              </AspectRatio>
              <VStack spacing={4} alignItems="flex-start" alignSelf="flex-start" zIndex="1">
                <Heading as="h3" color="white" height="5rem">Consensus and finalisation</Heading>
                <Box borderRadius="8px" backgroundColor="rgba(255, 255, 255, 0.20)" p="2" >
                  <Text as="span" color="white" opacity="0.5">
                    Available
                  </Text>
                </Box>
                <Text as="p" color="white" mb={2}>
                  Concordium has developed the first provably secure Finality Layer, which runs on top of a Nakamoto-Style Consensus layer. Transactions make it into the blockchain very quickly but may still be subject to a rollback until they are finalised. This two-layer approach gives our users secure finality in a fraction of the time when compared to other blockchains. This gives the user the option to favour speed or safety when accepting transactions.
                </Text>
                <Text as="p" color="white" mb={2}>
                  More features are on our roadmap to improve the usability and security
                </Text>
              </VStack>
            </Box>,
            <Box position="relative" backgroundColor="ciphers.blueZodiac" px={6} py={10} borderRadius={16} height="40rem">
              <AspectRatio
                width="100%"
                ratio={1 / 1}
                position="absolute"
                top="-1%"
                right="-50%"
                filter="blur(30px)"
                _after={{
                  content: '""',
                  position: "absolute",
                  top: 0,
                  right: 0,
                  bottom: 0,
                  left: 0,
                  zIndex: -1,
                  transform: "scale(1.2)",
                  clipPath: "polygon(50% 0, 90% 20%, 90% 80%, 50% 100%, 10% 80%, 10% 20%)",
                  bgGradient: 'linear(to-r, #38ffef, #292CBE)'
                }}
              >
                {/* Add empty Box because AspectRatio needs at least one child */}
                <Box></Box>
              </AspectRatio>
              <VStack spacing={4} alignItems="flex-start" alignSelf="flex-start" zIndex="1">
                <Heading as="h3" color="white" height="5rem">Digital Trust is Key</Heading>
                <Box borderRadius="8px" backgroundColor="rgba(255, 255, 255, 0.20)" p="2" >
                  <Text as="span" color="white" opacity="0.5">
                    Q1 2023
                  </Text>
                </Box>
                <Text as="p" color="white" mb={2}>
                  If no authenticated identity is connected to the blockchain transactions, no NFT is safe!
                </Text>
                <Text as="p" color="white" mb={2}>
                  Sure, all NFTs and transactions are publicly visible on the blockchain and thus anonymity is guaranteed, but that also has a big disadvantage, namely that money launderers, Fraud offenders and hackers cannot be traced and prosecuted.
                </Text>
                <Text as="p" color="white" mb={2}>
                  To trade precious crypto stamps safely and securely, Ciphers.me offers a marketplace built on the Concordium blockchain. The Concordium web wallet offers all the functionality like metamask, among others, but is then secure and regulated! There are more developments
                </Text>
              </VStack>
            </Box>,
            <Box position="relative" backgroundColor="ciphers.blueZodiac" px={6} py={10} borderRadius={16}>
              <AspectRatio
                width="100%"
                ratio={1 / 1}
                position="absolute"
                top="-35%"
                left="-40%"
                filter="blur(30px)"
                _after={{
                  content: '""',
                  position: "absolute",
                  top: 0,
                  right: 0,
                  bottom: 0,
                  left: 0,
                  zIndex: -1,
                  transform: "scale(1.75)",
                  clipPath: "polygon(50% 0, 90% 20%, 90% 80%, 50% 100%, 10% 80%, 10% 20%)",
                  bgGradient: 'linear(to-r, #A72CD4, #33159D)'
                }}
              >
                {/* Add empty Box because AspectRatio needs at least one child */}
                <Box></Box>
              </AspectRatio>
              <VStack spacing={4} alignItems="flex-start" alignSelf="flex-start" zIndex="1">
                <Heading as="h3" color="white" height="5rem">Bridging to other chains</Heading>
                <Box borderRadius="8px" backgroundColor="rgba(255, 255, 255, 0.20)" p="2" >
                  <Text as="span" color="white" opacity="0.5">
                    Q1 2023
                  </Text>
                </Box>
                <Text as="p" color="white" mb={2}>
                  The ciphers.me solution is built on the science backed Layer-1 blockchain with ID framework from our partner Concordium. This allows crypto stamp collectors to trade safely and securely on our soon to be launched marketplace.
                </Text>
                <Text as="p" color="white" mb={2}>
                  To trade on other blockchains and marketplaces from that secure environment, a trusted and safe 'bridge' is needed. This is currently being developed and is expected in the first quarter of this year.
                </Text>
              </VStack>
            </Box>,
            <Box position="relative" backgroundColor="ciphers.blueZodiac" px={6} py={10} borderRadius={16}>
              <AspectRatio
                width="100%"
                ratio={1 / 1}
                position="absolute"
                top="30%"
                left="-50%"
                filter="blur(30px)"
                _after={{
                  content: '""',
                  position: "absolute",
                  top: 0,
                  right: 0,
                  bottom: 0,
                  left: 0,
                  zIndex: -1,
                  transform: "scale(1.2)",
                  clipPath: "polygon(50% 0, 90% 20%, 90% 80%, 50% 100%, 10% 80%, 10% 20%)",
                  bgGradient: 'linear(to-r, #38ffef, #292CBE)'
                }}
              >
                {/* Add empty Box because AspectRatio needs at least one child */}
                <Box></Box>
              </AspectRatio>
              <VStack spacing={4} alignItems="flex-start" alignSelf="flex-start" zIndex="1">
                <Heading as="h3" color="white" height="5rem">Enhanced transaction speed</Heading>
                <Box borderRadius="8px" backgroundColor="rgba(255, 255, 255, 0.20)" p="2" >
                  <Text as="span" color="white" opacity="0.5">
                    Q2 2023
                  </Text>
                </Box>
                <Text as="p" color="white" mb={2}>
                  The most recent performance tests put the Transactions Per Minute (TPS) of Concordium at 400. Efficiency was a major workstream for them in 2022, which they will address from multiple angles: they will focus on tuning the existing Node implementations for vertical scaling, while implementing sharding for horizontal scalability.
                </Text>
                <Text as="p" color="white" mb={2}>
                  In 2023, efficiency will remain a key focus area for the engineering team as they add more features to the blockchain.
                </Text>
              </VStack>
            </Box>
          ]} />
      </Box>
    </Center>
  )

  const desktopGallery = () => (
    <Box mx="auto" position="relative">
      <Box width={{ base: "300px", md: "450px" }}>
        <Gallery
          aspectRatio={4 / 5}
          height={{ base: "45rem", md: "35rem" }}
          items={[
            <Box position="relative" backgroundColor="ciphers.blueZodiac" px={6} py={10} borderRadius={16}>
              <AspectRatio
                width="100%"
                ratio={1 / 1}
                position="absolute"
                bottom="-20%"
                right="-30%"
                filter="blur(30px)"
                _after={{
                  content: '""',
                  position: "absolute",
                  top: 0,
                  right: 0,
                  bottom: 0,
                  left: 0,
                  zIndex: -1,
                  transform: "scale(1.5)",
                  clipPath: "polygon(50% 0, 90% 20%, 90% 80%, 50% 100%, 10% 80%, 10% 20%)",
                  bgGradient: 'linear(to-r, #33159D, #38ffef)'
                }}
              >
                {/* Add empty Box because AspectRatio needs at least one child */}
                <Box></Box>
              </AspectRatio>
              <VStack spacing={4} alignItems="flex-start" alignSelf="flex-start" zIndex="1">
                <Heading as="h3" color="white" height="5rem">AR, DMS, NFC, RFID, etc.</Heading>
                <Box borderRadius="8px" backgroundColor="rgba(255, 255, 255, 0.20)" p="2" >
                  <Text as="span" color="white" opacity="0.5">
                    Available
                  </Text>
                </Box>
                <Text as="p" color="white" mb={2}>
                  Ciphers.me offers several innovations that can add extra value to the familiar postage stamp. From augmented reality to RFID and NFC chips, NFTs and blockchain based secure solutions. For consumers, these innovations mean that they can unlock interactive experiences with a stamp, or track and trace their shipments with a stamp.
                </Text>
                <Text as="p" color="white" mb={2}>
                  Postal organisations can use our digital technology to add value to the traditional stamp, prevent forgery, optimise processes and offer their customers additional functionality like augmented reality and metaverses
                </Text>
              </VStack>
            </Box>,
            <Box position="relative" backgroundColor="ciphers.blueZodiac" px={6} py={10} borderRadius={16}>
              <AspectRatio
                width="100%"
                ratio={1 / 1}
                position="absolute"
                top="-20%"
                right="-40%"
                filter="blur(30px)"
                transform="rotateZ(180deg)"
                _after={{
                  content: '""',
                  position: "absolute",
                  top: 0,
                  right: 0,
                  bottom: 0,
                  left: 0,
                  zIndex: -1,
                  clipPath: "polygon(50% 0, 90% 20%, 90% 80%, 50% 100%, 10% 80%, 10% 20%)",
                  bgGradient: 'linear(to-r, #A72CD4, #33159D)'
                }}
              >
                {/* Add empty Box because AspectRatio needs at least one child */}
                <Box></Box>
              </AspectRatio>
              <VStack spacing={4} alignItems="flex-start" alignSelf="flex-start" zIndex="1">
                <Heading as="h3" color="white" height="5rem">Consensus and finalisation</Heading>
                <Box borderRadius="8px" backgroundColor="rgba(255, 255, 255, 0.20)" p="2" >
                  <Text as="span" color="white" opacity="0.5">
                    Available
                  </Text>
                </Box>
                <Text as="p" color="white" mb={2}>
                  Concordium has developed the first provably secure Finality Layer, which runs on top of a Nakamoto-Style Consensus layer. Transactions make it into the blockchain very quickly but may still be subject to a rollback until they are finalised. This two-layer approach gives our users secure finality in a fraction of the time when compared to other blockchains. This gives the user the option to favour speed or safety when accepting transactions.
                </Text>
                <Text as="p" color="white" mb={2}>
                  More features are on our roadmap to improve the usability and security
                </Text>
              </VStack>
            </Box>,
            <Box position="relative" backgroundColor="ciphers.blueZodiac" px={6} py={10} borderRadius={16} height="40rem">
              <AspectRatio
                width="100%"
                ratio={1 / 1}
                position="absolute"
                top="-1%"
                right="-50%"
                filter="blur(30px)"
                _after={{
                  content: '""',
                  position: "absolute",
                  top: 0,
                  right: 0,
                  bottom: 0,
                  left: 0,
                  zIndex: -1,
                  transform: "scale(1.2)",
                  clipPath: "polygon(50% 0, 90% 20%, 90% 80%, 50% 100%, 10% 80%, 10% 20%)",
                  bgGradient: 'linear(to-r, #38ffef, #292CBE)'
                }}
              >
                {/* Add empty Box because AspectRatio needs at least one child */}
                <Box></Box>
              </AspectRatio>
              <VStack spacing={4} alignItems="flex-start" alignSelf="flex-start" zIndex="1">
                <Heading as="h3" color="white" height="5rem">Digital Trust is Key</Heading>
                <Box borderRadius="8px" backgroundColor="rgba(255, 255, 255, 0.20)" p="2" >
                  <Text as="span" color="white" opacity="0.5">
                    Q1 2023
                  </Text>
                </Box>
                <Text as="p" color="white" mb={2}>
                  If no authenticated identity is connected to the blockchain transactions, no NFT is safe!
                </Text>
                <Text as="p" color="white" mb={2}>
                  Sure, all NFTs and transactions are publicly visible on the blockchain and thus anonymity is guaranteed, but that also has a big disadvantage, namely that money launderers, Fraud offenders and hackers cannot be traced and prosecuted.
                </Text>
                <Text as="p" color="white" mb={2}>
                  To trade precious crypto stamps safely and securely, Ciphers.me offers a marketplace built on the Concordium blockchain. The Concordium web wallet offers all the functionality like metamask, among others, but is then secure and regulated! There are more developments
                </Text>
              </VStack>
            </Box>,
            <Box position="relative" backgroundColor="ciphers.blueZodiac" px={6} py={10} borderRadius={16}>
              <AspectRatio
                width="100%"
                ratio={1 / 1}
                position="absolute"
                top="-35%"
                left="-40%"
                filter="blur(30px)"
                _after={{
                  content: '""',
                  position: "absolute",
                  top: 0,
                  right: 0,
                  bottom: 0,
                  left: 0,
                  zIndex: -1,
                  transform: "scale(1.75)",
                  clipPath: "polygon(50% 0, 90% 20%, 90% 80%, 50% 100%, 10% 80%, 10% 20%)",
                  bgGradient: 'linear(to-r, #A72CD4, #33159D)'
                }}
              >
                {/* Add empty Box because AspectRatio needs at least one child */}
                <Box></Box>
              </AspectRatio>
              <VStack spacing={4} alignItems="flex-start" alignSelf="flex-start" zIndex="1">
                <Heading as="h3" color="white" height="5rem">Bridging to other chains</Heading>
                <Box borderRadius="8px" backgroundColor="rgba(255, 255, 255, 0.20)" p="2" >
                  <Text as="span" color="white" opacity="0.5">
                    Q1 2023
                  </Text>
                </Box>
                <Text as="p" color="white" mb={2}>
                  The ciphers.me solution is built on the science backed Layer-1 blockchain with ID framework from our partner Concordium. This allows crypto stamp collectors to trade safely and securely on our soon to be launched marketplace.
                </Text>
                <Text as="p" color="white" mb={2}>
                  To trade on other blockchains and marketplaces from that secure environment, a trusted and safe 'bridge' is needed. This is currently being developed and is expected in the first quarter of this year.
                </Text>
              </VStack>
            </Box>,
            <Box position="relative" backgroundColor="ciphers.blueZodiac" px={6} py={10} borderRadius={16}>
              <AspectRatio
                width="100%"
                ratio={1 / 1}
                position="absolute"
                top="30%"
                left="-50%"
                filter="blur(30px)"
                _after={{
                  content: '""',
                  position: "absolute",
                  top: 0,
                  right: 0,
                  bottom: 0,
                  left: 0,
                  zIndex: -1,
                  transform: "scale(1.2)",
                  clipPath: "polygon(50% 0, 90% 20%, 90% 80%, 50% 100%, 10% 80%, 10% 20%)",
                  bgGradient: 'linear(to-r, #38ffef, #292CBE)'
                }}
              >
                {/* Add empty Box because AspectRatio needs at least one child */}
                <Box></Box>
              </AspectRatio>
              <VStack spacing={4} alignItems="flex-start" alignSelf="flex-start" zIndex="1">
                <Heading as="h3" color="white" height="5rem">Enhanced transaction speed</Heading>
                <Box borderRadius="8px" backgroundColor="rgba(255, 255, 255, 0.20)" p="2" >
                  <Text as="span" color="white" opacity="0.5">
                    Q2 2023
                  </Text>
                </Box>
                <Text as="p" color="white" mb={2}>
                  The most recent performance tests put the Transactions Per Minute (TPS) of Concordium at 400. Efficiency was a major workstream for them in 2022, which they will address from multiple angles: they will focus on tuning the existing Node implementations for vertical scaling, while implementing sharding for horizontal scalability.
                </Text>
                <Text as="p" color="white" mb={2}>
                  In 2023, efficiency will remain a key focus area for the engineering team as they add more features to the blockchain.
                </Text>
              </VStack>
            </Box>
          ]} />
      </Box>
    </Box>
  )

  return (
    <Box as='section' id='roadmap' maxWidth="100%">
      <Box width={boxWidth} mx="auto" >
        <Box width={{ base: "100%", md: "60%" }}>
          <Heading as="h2" fontSize="4xl" mb={7} color="white" textAlign={{ base: "center", md: "left" }}>
            <Trans i18nKey={translation}>
              Placeholder <span className='magic'></span>
            </Trans>
          </Heading>
          <Text color="white" mb={14} textAlign={{ base: "center", md: "left" }}>{t("B2B.Home.Roadmap.Description")}</Text>
        </Box>
      </Box>
      {isMobile ? mobileGallery() : desktopGallery()}
    </Box >
  )
}
