import posterImage from '../../../../assets/b2b/home/poster-video.png'
import React, { useRef } from 'react'
import videosrc from '../../../../assets/b2b/home/CiphersPromoVideo.mp4'
import { AspectRatio, Box, Flex, Heading, Text, useBreakpointValue } from '@chakra-ui/react'
import { Trans, useTranslation } from 'react-i18next'

export const PromoVideo = () => {
  const boxWidth = useBreakpointValue({ base: "95%", sm: "90%", md: "723px", lg: "933px", xl: "1127px" });
  const { t } = useTranslation()
  const videoElement = useRef<HTMLVideoElement>(null);

  return (
    <Flex width={boxWidth} flexFlow="row wrap" justifyContent="space-between" alignItems="center" backgroundColor="bgSecondary" py={10} px={{ base: 6, md: 16 }}>
      <Box flexBasis={{ base: "100%", md: "30%" }} textAlign={{ base: "center", md: "left" }}>
        <Heading as="h2" color="white" fontSize={{ base: "3xl", md: "4xl" }} mb={6}>
          <Trans i18nKey="B2B.Home.PromoVideo.Title">
            Placeholder <span className='magic'></span>
          </Trans>
        </Heading>
        <Text as="p" color="white" fontSize={{ base: "md", md: "lg" }} mb={{ base: 10, md: 0 }}>{t("B2B.Home.PromoVideo.Description")}</Text>
      </Box>
      <AspectRatio flexBasis={{ base: "100%", md: "65%" }} ratio={16 / 9} >
        <Box position="relative" width="100%" height="100%" bgGradient='linear(to-r, #8721FF, #FF36AD)' p={1}>
          <video
            src={videosrc}
            width='100%'
            controls
            ref={videoElement}
            poster={posterImage}
            playsInline
          />
        </Box>
      </AspectRatio>
    </Flex >
  )
}
