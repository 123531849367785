import { b2bNavbarRoutes } from './_data'
import { Box, Button, Divider, Flex, VStack } from '@chakra-ui/react'
import { GetAllowedLinkRoutes } from '../../routes/RouteUtils'
import { history } from '../../ApplicationRouter'
import { Link as ReachLink, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

interface IProps {
  onClickMenu: VoidFunction;
}

export const NavbarMobileB2B = ({ onClickMenu }: IProps) => {
  const { t } = useTranslation();
  let location = useLocation();

  const b2bNavbarRoutesMenu = GetAllowedLinkRoutes(b2bNavbarRoutes);

  const isCurrentRouteSelected = (currentRoute: string): boolean => {
    if (location.pathname === ("/" || "/postal-organisations") && currentRoute === "/postal-organisations") {
      return true
    } else if (location.pathname === currentRoute) {
      return true
    }
    return false;
  }

  const handleClick = (path: string) => {
    onClickMenu();
    history.push(path);
  }

  return (
    <>
      <Flex direction="column" py={{ base: '3', lg: '4' }} h="100%">
        <Flex direction="column" justifyContent="space-between" my="2" height="100%">
          <VStack spacing="6" align="center" divider={<Divider backgroundColor="white.300" />}>
            {b2bNavbarRoutesMenu.map((route, i) => (
              <Button
                justifyContent="left"
                as={ReachLink}
                to={route.href}
                key={i}
                onClick={() => handleClick(route.href)}
                bgGradient={isCurrentRouteSelected(route.href) ? "linear(to-r,#8721FF, #38FFEF)" : "linear(to-r,white, white)"}
                bgClip='text'
                fontSize={"3xl"}
              >
                {t(route.label)}{route.badge && <Box as="span" marginStart="2">{route.badge}</Box>}
              </Button>
            ))}
          </VStack>
          <Button onClick={() => handleClick("/contact")} width="100%" height={12} backgroundColor="#4129FF" fontSize="xs" borderRadius={2} letterSpacing={2} color="white">GET YOUR STAMP</Button>
        </Flex>

      </Flex>
    </>
  )
}