import { Badge, LightMode } from '@chakra-ui/react'
import { ILinkItem } from '../../models/routes/ILinkGroup'
import { Roles } from '../../enums/Roles'
import { SeriesNavMenu } from './series/SeriesNavMenu'

export const b2bNavbarRoutes: ILinkItem[] = [
  {
    label: "Postal Organisations",
    href: "/postal-organisations",
  },
  {
    label: "About us",
    href: "/about-us",
  },
  {
    label: "Contact",
    href: "/contact",
  },
]

export const publicNavbarRoutes: ILinkItem[] = [
  {
    label: "Navbar.Public.Home",
    href: "/",
  },
  {
    label: "Navbar.Public.Register",
    href: "/register",
  },
  {
    label: "Navbar.Public.Login",
    href: "/login",
  }
];

export const authorizedNavbarRoutes: ILinkItem[] = [
  {
    label: "Navbar.Authorized.Home",
    href: "/",
  },
  {
    label: "Navbar.Authorized.All_Collections",
    href: "/collections",
    badge: (
      <LightMode>
        <Badge colorScheme="blue" fontSize="0.625rem">
          New!
        </Badge>
      </LightMode>
    ),
    menu: <SeriesNavMenu />
  },
  {
    label: "Navbar.Authorized.My_Collections",
    href: "/customer",
    roles: [Roles.CUSTOMER]
  },
  {
    label: "Navbar.Authorized.Favorites",
    href: "/favorites",
  }
];

export const authorizedNavbarSubRoutes: ILinkItem[] = [
  {
    label: "Overview",
    href: "/overview"
  },
  {
    label: "Analytics",
    href: "/analytics"
  },
  {
    label: "Alerts",
    href: "/alerts"
  },
]