import { ArrowForwardIcon } from '@chakra-ui/icons'
import { AspectRatio, Box, Flex, Heading, Link as ChakraLink, Stack, Text, useBreakpointValue, VStack } from '@chakra-ui/react'
import { Link } from 'react-router-dom'

export const JobListings = () => {
  const isMobile = useBreakpointValue({ base: true, lg: false })
  return (
    <section id="job-listings">
      <Box w="full" bgColor={{ base: "unset", lg: "bgSecondary" }} p={{ base: "4", lg: "8" }}>
        <Heading as="h1" fontSize="4xl" mb={10} color="white" textAlign={{ base: "center", lg: "left" }}>We're hiring<br /> Join our <span className="magic">team of experts</span></Heading>
        <Stack spacing="6" mb="-16" direction={isMobile ? "column" : "row"} justifyContent="space-between">
          <Box pos="relative" h="500px" w={{ base: "100%", lg: "500px" }} bgColor="bgTertiary" overflow="hidden" borderRadius="14px">
            <VStack pos="absolute" px="6" py="12" alignItems="flex-start !important" zIndex="2" height="100%" width="100%">
              <Heading as="h1" maxW={{ base: "100%", lg: "80%" }} color="white" mb={2}>
                Back-end <br />
                Developer
              </Heading>
              <Box borderRadius="5px" backgroundColor="rgba(255, 255, 255, 0.20)" px="6" >
                <Text as="span" color="white" opacity="0.5">
                  Development
                </Text>
              </Box>
              <Box w="100%" flex="1">
                <Text fontSize="medium" lineHeight="1.8" mt={{ base: "4", lg: "8" }} maxW={{ base: "100%", lg: "90%" }} color="white">
                  As a Back-end Developer, you will join a driven team at a young and fast-growing company engaged in highly innovative and ground-breaking projects in the field of WEB 3, NFTs, smart contracts, digital wallets, Zero Knowledge Proof, identification and Blockchain.
                </Text>
              </Box>
              <Flex as={ChakraLink} href='https://www.linkedin.com/jobs/view/3454926013/' alignItems="center" _hover={{ textDecoration: "none" }}>
                <ArrowForwardIcon color="white" mr={2} boxSize={5} />
                <Text as="span" color="white" fontSize="lg">
                  More information
                </Text>
              </Flex>
            </VStack>
            <AspectRatio
              width="100%"
              ratio={1 / 1}
              position="absolute"
              top="-50%"
              left="-20%"
              filter="blur(30px)"
              _after={{
                content: '""',
                position: "absolute",
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                zIndex: -1,
                transform: "scale(1.3)",
                clipPath: "polygon(50% 0, 90% 20%, 90% 80%, 50% 100%, 10% 80%, 10% 20%)",
                bgGradient: 'linear(to-r, #A72CD4, #33159D)'
              }}
            >
              {/* Add empty Box because AspectRatio needs at least one child */}
              <Box></Box>
            </AspectRatio>
          </Box>
          <Box pos="relative" h="500px" w={{ base: "100%", lg: "500px" }} bgColor="bgTertiary" overflow="hidden" borderRadius="14px">
            <VStack pos="absolute" px="6" py="12" alignItems="flex-start !important" zIndex="2" height="100%" width="100%">
              <Heading as="h1" maxW={{ base: "100%", lg: "80%" }} color="white" mb={2}>
                Open <br /> application
              </Heading>

              <Box borderRadius="5px" opacity=".8" backgroundColor="rgba(255, 255, 255, 0.20)" px="6">
                <Text as="span" color="white" opacity="0.5">
                  All areas
                </Text>
              </Box>
              <Box w="100%" flex="1">
                <Text fontSize="medium" lineHeight="1.8" mt={{ base: "4", lg: "8" }} maxW={{ base: "100%", lg: "90%" }} color="white">
                  If you are looking for a challenge in the emerging WEB3 universe, don't hesitate to contact us! Web3-based solutions like Ciphers.me require different expertise. If you think you have something to offer us for this, don't hesitate to contact us or schedule an appointment. We would love to get in touch with you!
                </Text>
              </Box>
              <Flex as={Link} to='/contact' alignItems="center" >
                <ArrowForwardIcon color="white" mr={2} boxSize={5} />
                <Text as="span" color="white" fontSize="lg">
                  More information
                </Text>
              </Flex>
            </VStack>
            <AspectRatio
              width="100%"
              ratio={1 / 1}
              position="absolute"
              top="-12%"
              right="-50%"
              filter="blur(30px)"
              _after={{
                content: '""',
                position: "absolute",
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                zIndex: -1,
                transform: "scale(1)",
                clipPath: "polygon(50% 0, 90% 20%, 90% 80%, 50% 100%, 10% 80%, 10% 20%)",
                bgGradient: 'linear(to-l, #33159D, #38ffef)'
              }}
            >
              {/* Add empty Box because AspectRatio needs at least one child */}
              <Box></Box>
            </AspectRatio>
          </Box>
        </Stack>
      </Box>
    </section>
  )
}