import React from 'react'
import { AutoWidthCenter } from '../../../components/layout/AutoWidthCenter'
import { FadeIn } from '../../../components/animation/FadeIn'
import { Header } from '../../../components/common/header/Header'
import { Image, Stack, Text } from '@chakra-ui/react'
import { imageOrPlaceholder } from '../../../utils/General'
import { NewsletterSignupForm } from '../../../components/b2b/NewsletterSignupForm'

export const CollectorsB2B = () => {
  return (
    <FadeIn>
      <AutoWidthCenter>
        <Header title='Collectors' subTitle='Start to learn, collect and trade crypto stamps' />

        <Image borderRadius={{ base: "0px", md: "14px" }} height={{ base: "20em", md: "30em", lg: "40em" }} width="100%" objectFit="cover" src={imageOrPlaceholder()} />

        <Stack spacing={5} py={8} px={[4, 4, 0]}>
          <Text fontSize="larger">
            At ciphers.me we have worldclass crypto stamp and NFT design and printing techniques in-house to offer you the best from our postal partners worldwide.
          </Text>
          <Text fontSize="larger">
            Our partner Royal Joh. Enschedé, founded in 1703, is known worldwide for its attractive stamps with attention to detail, technical expertise and quality.
            They are the specialist for secured and extremely reliable printing and security solutions. Based on craftsmanship and expertise, they provide security printingsolutions and innovations against fraud and counterfeiting. Combine this with the best WEBS software development of PROxID on the science-backed Layer-I
            solutions and innovations against fraud and counterfeiting. Combine this with the best WEBS software development of PROxID on the science-backed Layer-I
            blockchain with ID-framework from Concordium guarantees you the best crypto stamp solution.
          </Text>
          <Text fontSize="larger">
            Any open-minded stamp and crypto enthusiast will benefit from delving into one of society's oldest and greatest collecting hobbies in crypto format. The motifs
            of static paper stamps become a living and interactive image, compensating for the loss of tangibility of the original stamp.
          </Text>
          <Text fontSize="larger">
            With today's stamp collectors in mind, limited and unique digital variants will become valuable collectibles and an exciting alternative investment opportunity.All NFTs, key elements of each collectible product, such as scarcity, ownership and price will be fully visible and traceable in the marketplace when you
            All NFTs, key elements of each collectible product, such as scarcity, ownership and price will be fully visible and traceable in the marketplace when you
            become a member of our unique and secure ciphers.me community.
          </Text>
        </Stack>

        <Image borderRadius={{ base: "0px", md: "14px" }} height={{ base: "20em", md: "30em", lg: "40em" }} width="100%" objectFit="cover" src={imageOrPlaceholder()} />

        <Stack spacing={5} py={8} px={{ base: 4, md: 0 }}>
          <Text fontSize="larger">
            Starting with Ciphers.me is easy as one two three
          </Text>
          <Text fontSize="larger">
            When you bought your paper crypto stamp you can scan the first of the two QR codes on the purchased paper crypto stamp. This takes you to the
            Ciphers.me website where you can register for your personal LIGHT account. When you scan or enter the second secret key pair you claim the digital twin
            that represents the unique NFT. This digital twin is then added to your personal collection, similar to collecting paper stamps in a stamp collector's book but
            then digitally
          </Text>
          <Text fontSize="larger">
            Ciphers.me membership
            When you want to trade your valuable stamps on the international ciphers.me marketplace, you can become a member of the ciphers.me community by
            creating a Concordium web wallet with your ID-document. After going through the identification process, you can connect the web wallet to your LIGHT
            account and then safely exchange and trade selected crypto stamps NFT's on the ciphers.me international marketplace for crypto stamp collectors.
          </Text>
          <Text fontSize="larger">
            Easy as 1,2,3
          </Text>
        </Stack>
      </AutoWidthCenter>
      <NewsletterSignupForm />
    </FadeIn>
  )
}
